// extracted by mini-css-extract-plugin
export var card1 = "styles-module--card1--31ntV";
export var card2 = "styles-module--card2--2UEtu";
export var card3 = "styles-module--card3--3PXJQ";
export var card4 = "styles-module--card4--i7yzz";
export var card1_light = "styles-module--card1_light--2RN5z";
export var card1_dark = "styles-module--card1_dark--2Y6Q5";
export var card2_light = "styles-module--card2_light--3F9P0";
export var card2_dark = "styles-module--card2_dark--8_xnO";
export var block = "styles-module--block--3jfAn";
export var container = "styles-module--container--PJna-";